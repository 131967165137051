import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Alert } from '../Alert';
import { toast } from 'react-toastify';

export const LoginCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const error = searchParams.get('error');
  const errorDescription = searchParams.get('error_description');

  useEffect(() => {
    if (error) {
      // Decode the error description if it exists
      const decodedErrorDescription = errorDescription ? decodeURIComponent(errorDescription) : 'An error occurred during login';

      // Show error in toast
      toast.error(decodedErrorDescription);

      // Determine redirect based on error type
      const isOrgError = decodedErrorDescription.toLowerCase().includes('organization');

      // Redirect to logout if it's an organization error, otherwise home
      const timer = setTimeout(() => {
        navigate(isOrgError ? '/logout' : '/', { replace: true });
      }, 8000);

      return () => clearTimeout(timer);
    }
  }, [error, errorDescription, navigate]);

  if (error) {
    const decodedErrorDescription = errorDescription ? decodeURIComponent(errorDescription) : 'An error occurred during login';
    const isOrgError = decodedErrorDescription.toLowerCase().includes('organization');

    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full space-y-8 p-8">
          <div className="text-center">
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Access Denied</h2>
            <p className="mt-2 text-sm text-gray-600">
              {decodedErrorDescription}
            </p>
          </div>
          <div className="mt-8">
            <Alert
              variant="error"
              message={`You will be redirected to ${isOrgError ? 'logout' : 'the home page'} in a few seconds...`}
              autoDismiss={false}
            />
          </div>
        </div>
      </div>
    );
  }

  return null;
};
