import { Disclosure, Menu, Transition } from '@headlessui/react';
import { UserIcon, XMarkIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { Fragment, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import fireflaiEmblem from '../../static/images/fireflai_emblem.png';
import { capitalise } from '../../utils';
import clsx from 'clsx';
import { useGetProfileDataQuery } from '../../store/profileData';
import { Product } from '../../services/products';
import { useProductAccess } from '../../hooks/useProductAccess';

interface NavigationItem {
  name: string;
  path: string;
  requiredPermissions?: string[];
  requiredProducts?: Product[];
}

const navigation: NavigationItem[] = [
  { name: 'Dashboard', path: '/' },
  { name: 'Asset Library', path: '/asset-library', requiredProducts: [Product.ASSET_LIBRARY] },
  { name: 'Enrichment', path: '/enrichment', requiredProducts: [Product.ENRICHMENT] },
  { name: 'MDM', path: '/mdm', requiredProducts: [Product.MDM] },
  { name: 'Users', path: '/users/list', requiredPermissions: ['users:read'] },
  { name: 'Subscription', path: '/subscription/overview', requiredPermissions: ['subscriptions:read'] }
];

const userNavigation = [
  { name: 'Your Profile', path: '/profile' },
  { name: 'Sign out', path: '/logout' }
];

export const Navbar = () => {
  const location = useLocation();
  const { hasAccess } = useProductAccess();
  const { isLoading: isLoadingProfileData, data: profileData } = useGetProfileDataQuery();

  const activeRoute = useMemo(() => {
    if (location.pathname === '/') return 'dashboard';
    return location.pathname.split('/')[1];
  }, [location.pathname]);

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img className="h-8 w-auto" src={fireflaiEmblem} alt="Fireflai" />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => {
                      const itemEnabled = hasAccess(item.requiredProducts, item.requiredPermissions);
                      return (
                        <Link
                          key={item.name}
                          to={itemEnabled ? item.path : '#'}
                          className={clsx(
                            activeRoute === item.name
                              ? 'text-white bg-white bg-opacity-10 rounded-3xl'
                              : 'text-navbar-text hover:bg-white hover:bg-opacity-10 rounded-3xl hover:text-white',
                            'rounded-3xl tracking-tight px-3 font-medium py-2 text-sm transition',
                            itemEnabled ? '' : '!text-gray-600 cursor-not-allowed'
                          )}
                          aria-current={activeRoute === item.name ? 'page' : undefined}
                        >
                          {capitalise(item.name)}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      {!isLoadingProfileData && profileData && profileData.pictureUrl ? (
                        <img
                          className="h-8 w-8 rounded-full"
                          src={profileData.pictureUrl}
                          alt="Your profile Avatar"
                        />
                      ) : (
                        <UserIcon className="h-8 w-8 text-gray-400" />
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              to={item.path}
                              className={clsx(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="border-b border-gray-700 md:hidden">
            <div className="space-y-1 px-2 py-3 sm:px-3">
              {navigation.map((item) => {
                const itemEnabled = hasAccess(item.requiredProducts, item.requiredPermissions);
                return (
                  <Link
                    key={item.name}
                    to={itemEnabled ? item.path : '#'}
                    className={clsx(
                      activeRoute === item.name
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block rounded-md px-3 py-2 text-base font-medium',
                      itemEnabled ? '' : '!text-gray-600 cursor-not-allowed'
                    )}
                    aria-current={activeRoute === item.name ? 'page' : undefined}
                  >
                    {capitalise(item.name)}
                  </Link>
                );
              })}
            </div>
            <div className="border-t border-gray-700 pb-3 pt-4">
              <div className="flex items-center px-5">
                {!isLoadingProfileData && profileData && profileData.pictureUrl ? (
                  <img className="h-8 w-8 rounded-full" src={profileData.pictureUrl} alt="Your profile Avatar" />
                ) : (
                  <div className="flex-shrink-0">
                    <UserIcon className="w-8 h-8 bg-violet-700 rounded-full text-white" />
                  </div>
                )}

                <div className="ml-3">
                  <div className="text-base font-medium leading-none text-white">{profileData?.name}</div>
                  <div className="text-sm font-medium leading-none text-gray-400">{profileData?.email}</div>
                </div>
              </div>
              <div className="mt-3 space-y-1 px-2">
                {userNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.path}
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
