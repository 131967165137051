import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
    ChartBarIcon,
    UserGroupIcon,
    CreditCardIcon,
    CogIcon,
    TableCellsIcon,
    ArrowPathIcon
} from '@heroicons/react/24/outline';
import { Product } from '../../services/products';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useProductAccess } from '../../hooks/useProductAccess';

export function Dashboard() {
    const { user } = useAuth0();
    const { hasAccess } = useProductAccess();

    const navigationSections = [
        {
            title: 'Asset Library',
            description: 'Manage and organize your data assets to support your Enrichment and MDM projects',
            icon: <ChartBarIcon className="h-8 w-8 text-blue-500" />,
            path: '/asset-library',
            requiredProducts: [Product.ASSET_LIBRARY]
        },
        {
            title: 'Enrichment',
            description: `Expand your data with Fireflai's enrichment tools`,
            icon: <ArrowPathIcon className="h-8 w-8 text-indigo-500" />,
            path: '/enrichment',
            requiredProducts: [Product.ENRICHMENT]
        },
        {
            title: 'MDM',
            description: `Take your scattered data to the next level with Fireflai's Master Data Management tools`,
            icon: <TableCellsIcon className="h-8 w-8 text-red-500" />,
            path: '/mdm',
            requiredProducts: [Product.MDM]
        },
        {
            title: 'User Management',
            description: 'Add, remove, and configure user accounts for your organization',
            icon: <UserGroupIcon className="h-8 w-8 text-purple-500" />,
            path: '/users/list',
            requiredPermissions: ['users:write']
        },
        {
            title: 'Subscription',
            description: 'Manage your subscription and billing',
            icon: <CreditCardIcon className="h-8 w-8 text-emerald-500" />,
            path: '/subscription/overview',
            requiredPermissions: ['subscriptions:read']
        },
        {
            title: 'Profile Settings',
            description: 'Customize your user profile and preferences',
            icon: <CogIcon className="h-8 w-8 text-gray-500" />,
            path: '/profile'
        }
    ];

    useEffect(() => {
        document.title = 'Dashboard | Fireflai';
    });

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            {/* Welcome Section */}
            <div className="mb-8">
                <h1 className="text-2xl font-bold text-gray-900">Welcome{user?.name ? `, ${user.name}` : ''}!</h1>
                <p className="mt-2 text-gray-600">Get started by exploring one of the sections below</p>
            </div>

            {/* Quick Stats */}
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8">
                {['Assets', 'Enrichment Projects', 'MDM Projects', 'Users'].map((stat) => (
                    <div key={stat} className="bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 truncate">{stat}</dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900">-</dd>
                        </div>
                    </div>
                ))}
            </div>

            {/* Navigation Cards */}
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {navigationSections.map((section) => {
                    const itemEnabled = hasAccess(section.requiredProducts, section.requiredPermissions);
                    return (
                        <Link
                            key={section.title}
                            to={itemEnabled ? section.path : '#'}
                            className={clsx(
                                'block p-6  border border-gray-200 rounded-lg shadow hover:shadow-md transition-shadow',
                                itemEnabled ? 'cursor-pointer bg-white' : 'cursor-not-allowed bg-gray-200'
                            )}
                        >
                            <div className="flex items-center mb-4">
                                {section.icon}
                                <h2 className="ml-3 text-lg font-semibold text-gray-900">{section.title}</h2>
                            </div>
                            <p className="text-gray-600">{section.description}</p>
                        </Link>
                    );
                })}
            </div>

            {/* Recent Activity - Placeholder */}
            <div className="mt-12">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Recent Activity</h2>
                <div className="bg-white shadow overflow-hidden sm:rounded-md">
                    <div className="p-6 text-center text-gray-500">No recent activity to display</div>
                </div>
            </div>
        </div>
    );
}
