import { Link, createBrowserRouter } from 'react-router-dom';

import { AppLayout } from '../components/AppLayout';
import { DashboardLayout } from '../pages';
import { Logout } from '../pages/Logout';
import { Auth0ProviderWithNavigate, AuthenticationGuard, LoginCallback, Redirect404 } from '../components';
import { USERS_ROUTES } from './Users.routes';
import { SUBSCRIPTION_ROUTES } from './Subscription.routes';
import { PROFILE_SETTINGS_ROUTES } from './ProfileSettings.routes';
import { defaultHandle } from './utils.routes';
import { DATA_ROUTES } from './DataRoutes';
import { MDM_ROUTES } from './MdmRoutes';
import { ENRICHMENT_ROUTES } from './EnrichmentRoutes';
import { ASSET_LIBRARY_ROUTES } from './AssetLibraryRoutes';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { ProductAccessGuard } from '../components/ProductAccessGuard';
import { Product } from '../services/products';

const wrapWithProductGuard = (routes: any[], requiredProducts?: Product[], requiredPermissions?: string[]) => {
  return routes.map(route => ({
    ...route,
    element: (
      <ProductAccessGuard requiredProducts={requiredProducts} requiredPermissions={requiredPermissions}>
        {route.element}
      </ProductAccessGuard>
    )
  }));
};

export const browserRouter = createBrowserRouter([
  {
    element: <Auth0ProviderWithNavigate />,
    children: [
      {
        path: '/',
        element: <AuthenticationGuard component={AppLayout} />,
        handle: {
          crumb: () => <Link to="/">Dashboard</Link>
        },
        children: [
          {
            path: '/',
            element: <DashboardLayout />,
            handle: defaultHandle,
            children: [
              {
                index: true,
                element: <Dashboard />,
                handle: {
                  crumb: () => <Link to="/">Dashboard</Link>
                }
              },
              ...wrapWithProductGuard(ASSET_LIBRARY_ROUTES, [Product.ASSET_LIBRARY]),
              ...wrapWithProductGuard(USERS_ROUTES, undefined, ['users:read']),
              ...wrapWithProductGuard(SUBSCRIPTION_ROUTES, undefined, ['subscriptions:read']),
              ...PROFILE_SETTINGS_ROUTES,
              ...DATA_ROUTES,
              ...wrapWithProductGuard(MDM_ROUTES, [Product.MDM]),
              ...wrapWithProductGuard(ENRICHMENT_ROUTES, [Product.ENRICHMENT])
            ]
          }
        ]
      },
      {
        path: 'logout',
        element: <Logout />,
        handle: defaultHandle
      },
      {
        path: '/login-callback',
        element: <LoginCallback />
      },
      {
        path: '*',
        element: <Redirect404 />
      }
    ]
  }
]);
