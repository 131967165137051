import { useAuth0 } from '@auth0/auth0-react';
import { store } from '../store/store';
import { getUserToken } from '../store/auth';
import { Product } from '../services/products';

export function useProductAccess() {
  const { user } = useAuth0();
  const userToken = getUserToken();
  const state = store.getState();
  const userPermissions = state.auth.decodedToken?.permissions;

  const hasProductAccess = (product: Product): boolean => {
    return userToken.ff_products?.includes(product) ?? false;
  };

  const hasPermission = (permission: string): boolean => {
    return userPermissions?.includes(permission) ?? false;
  };

  const hasAccess = (requiredProducts?: Product[], requiredPermissions?: string[]): boolean => {
    const productsEnabled = (requiredProducts ?? []).every((product) => hasProductAccess(product));
    const permissionsEnabled = (requiredPermissions ?? []).every((permission) => hasPermission(permission));
    return productsEnabled && permissionsEnabled;
  };

  return {
    hasProductAccess,
    hasPermission,
    hasAccess,
    user,
    userToken
  };
} 