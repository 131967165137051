import { Navigate } from 'react-router-dom';
import { Product } from '../services/products';
import { useProductAccess } from '../hooks/useProductAccess';

interface ProductAccessGuardProps {
    requiredProducts?: Product[];
    requiredPermissions?: string[];
    children: React.ReactNode;
}

export function ProductAccessGuard({ requiredProducts, requiredPermissions, children }: ProductAccessGuardProps) {
    const { hasAccess } = useProductAccess();

    if (!hasAccess(requiredProducts, requiredPermissions)) {
        return <Navigate to="/" replace />;
    }

    return <>{children}</>;
} 